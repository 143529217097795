<div class="container">
  <h3 class="text-center">Webseite Glasbläserei Egli</h3>
  <p>
    Als ein Familienbetrieb mit einer langen Tradition wurde die Glasbläserei-Egli von meinen Großeltern gegründet und
    wird nun von meiner Mutter als Einzelunternehmerin weitergeführt. Angesichts der fortschreitenden Digitalisierung
    habe ich dazu beigetragen, einige Aspekte des Unternehmens zu digitalisieren und zu optimieren. Um meine bestehenden
    Fähigkeiten zu vertiefen und neue zu erwerben, habe ich die Webseite für die Glasbläserei-Egli entwickelt.
  </p>
  <h4>Webseitenbeschreibung:</h4>
  <p>
    Die Webseite dient als digitale Präsenz für die Glasbläserei-Egli, die im Besitz meiner Mutter ist. Neben der
    Geschichte des Unternehmens werden auch die Hauptprodukte vorgestellt. Die Website bietet einen Admin-Bereich, der
    es ermöglicht, Daten einfach zu bearbeiten und die Online-Präsenz effektiv zu verwalten.
  </p>
  <h4>Verwendete Technologien:</h4>
  <ul>
    <li><b>Frontend:</b> Angular, HTML 5, CSS 3, Angular Material</li>
    <li><b>Backend:</b> ASP.NET, C# (RESTful API)</li>
    <li><b>Datenbank:</b> Microsoft SQL</li>
  </ul>
  <h4>Umsetzung:</h4>
  <p>
    Die Webseite wurde mit Angular umgesetzt, wobei verschiedene Angular Material Komponenten für ein modernes und
    ansprechendes Design verwendet wurden. Ein spezieller Admin-Bereich wurde mit einer Login-Funktion eingerichtet, um
    Daten bequem zu verwalten. Im Backend wurde eine RESTful API mit .NET in C# programmiert, um eine effiziente
    Kommunikation zwischen Frontend und Datenbank zu gewährleisten. Die Daten selbst werden in einer Microsoft
    SQL-Datenbank gespeichert.
  </p>
  <p>
    Die Webseite Glasbläserei-Egli präsentiert nicht nur die handwerkliche Tradition des Familienunternehmens, sondern
    zeigt auch meine Fähigkeiten in der Entwicklung digitaler Lösungen und meine Bereitschaft, die Familientradition mit
    zeitgemäßer Technologie zu verbinden.
  </p>
  <div class="buttonContainer">
    <button class="btn btn-success" (click)="onClick()">Zu Glasbläserei-Egli.ch</button>
  </div>

</div>

