<div class="container">
  <h3>Über mich:</h3>
  <code>Stand 17.12.2023</code>
  <section class="mt-4">
    <div class="row">
      <h5>Personalien:</h5>
    </div>
    <div class="row title">
      Name:
    </div>
    <div class="row mb-2">
      Patrick Tomasi
    </div>
    <div class="row title">
      Ort:
    </div>
    <div class="row mb-2">
      4468 Kienberg, Schweiz
    </div>
    <div class="row title">
      Job:
    </div>
    <div class="row">
      Software Developer
    </div>
  </section>
  <section class="mt-5">
    <div class="row">
      <h5>Berufliche Tätigkeiten:</h5>
    </div>
    <div class="row title">
      Seit 08.2020
    </div>
    <div class="row mb-2">
      <span class="company">Applikationsentwickler VistaSys AG</span>
      <ul>
        <li>Rest API's in .NET 6</li>
        <li>Web-Applikationen  mit Angular und .NET Core Backend</li>
        <li>Ablösung von Silverlight Applikationen mit Angular</li>
        <li>Erweiterung Absence Manager (IPA)</li>
        <li>Umsetzungen von Projekten mit .NET (c#, ASP.NET)</li>
      </ul>
    </div>
    <div class="row title">
      08.2019 - 08.2020
    </div>
    <div class="row mb-2">
      <span class="company">Praktikant VistaSys AG</span>
      <ul>
        <li>Umsetzen von Projekten im .NET Bereich</li>
        <li>Allgemeine Supportarbeiten (Applikationssupport)</li>
        <li>Begleitung von Kundenprojekten</li>
      </ul>
    </div>
    <div class="row title">
      07.2018 - 10.2018
    </div>
    <div class="row mb-2">
      <span class="company">Allrounder Metallveredlung Kopp AG</span>
      <ul>
        <li>Mitarbeit in der  Produktion Galvanik</li>
        <li>Mitarbeit im Lager</li>
        <li>Mitarbeit in der Verpackung</li>
      </ul>
    </div>
    <div class="row title">
      09.2015 - 06.2018
    </div>
    <div class="row mb-2">
      <span class="company">Küchenchef Konditorei Himmel</span>
      <ul>
        <li>Mittagsmenu planen und einkaufen</li>
        <li>Anleitung von Mitarbeitenden</li>
        <li>Mithilfe im Service und Verkauf</li>
      </ul>
    </div>
    <div class="row title">
    01.2012 - 08.2015
  </div>
    <div class="row mb-2">
      <span class="company">Küchenchef Bäckerei Studler</span>
      <ul>
        <li>Einkauf und Planung Speiseangebot Restaurant</li>
        <li>Führen des Küchenteams</li>
        <li>Verantwortlich für einwandfreie Lagerhaltung und Hygienekontrolle</li>
      </ul>
    </div>
    <div class="row title">
      05.2005 - 12.2011
    </div>
    <div class="row">
      <span class="company">Stellvertretender Küchenchef Restaurant Schönau</span>
      <ul>
        <li>Zuständig für Einkauf und Menuplanung</li>
        <li>Lehrlingsausbildung</li>
        <li>Verantwortlich für das Einhalten der Hygienevorschriften </li>
      </ul>
    </div>
  </section>
  <section class="mt-5">
    <div class="row">
      <h5>Ausbildung:</h5>
    </div>
    <div class="row title">
      09.2018 - 08.2020
    </div>
    <div class="row mb-2">
      Ausbildung zum Informatiker Applikationsentwicklung EFZ
    </div>
    <div class="row title">
      08.2000 - 08.2003
    </div>
    <div class="row">
      Ausbildung als Koch EFZ
    </div>
  </section>
  <section class="mt-5">
    <div class="row">
      <h5>Zertifikate:</h5>
    </div>
    <div class="row title">
      Microsoft
    </div>
    <div class="row mt-2">
      <ul>
        <li><a href="https://www.credly.com/badges/7696245a-299e-46dc-811b-a3a48c3052d5/public_url" target="_blank">
          Microsoft Certified: Azure Fundamentals (AZ-900)</a></li>
        <li>
          <a href="https://www.credly.com/badges/7bd6c424-505b-4f4e-8111-2be4951ca5c1" target="_blank">
            Microsoft Certified: Azure Developer Associate (AZ-204)
          </a>
        </li>
      </ul>
    </div>
    <div class="row title">
      Angular
    </div>
    <div class="row mt-2">
      <ul>
        <li><a href="https://interstate21.com/certificate/?code=2G22BDN" target="_blank">JUNIOR ANGULAR DEVELOPER</a></li>
        <li><a href="https://interstate21.com/certificate/?code=4G05XLH" target="_blank">ANGULAR CERTIFIED DEVELOPER</a></li>
      </ul>
    </div>
    <div class="row title">
      The Things Network
    </div>
    <div class="row mt-2">
      <ul>
        <li><a href="../../assets/pdf/The_Things_Certified_Fundamentals.pdf" target="_blank">The Things Certified Fundamentals</a></li>
        <li><a href="../../assets/pdf/The_Things_Certified_Advanced.pdf" target="_blank">The Things Certified Advanced</a></li>
      </ul>
    </div>
  </section>
  <section class="mt-5">
    <div class="row">
      <h5>Freizeit:</h5>
      <div class="row mb-2">
        <ul>
          <li>IT</li>
          <li>Computerspiele</li>
          <li>Spielkonsolen sammeln</li>
          <li>Angel</li>
          <li>Kochen</li>
        </ul>
      </div>
    </div>
  </section>
</div>
