<div class="container">
  <h3 class="text-center">Felchen Webapp - Angeln leicht gemacht:</h3>
  <section>
    <p>
      Die Felchen Webapp ist die Weiterentwicklung der Android Felchen App und wurde als Webanwendung komplett neu
      konzipiert. Diese Version bietet den Vorteil, dass sie als normale Webseite von jedem Gerät mit Internetzugang und
      Browser geöffnet werden kann, was die Plattformunabhängigkeit und schnellere Updates ermöglicht.
    </p>
  </section>
  <section>
    <gallery *ngIf="galleryConfig$ | async; let config"
             [items]="images"
             [thumbWidth]="config.thumbWidth"
             [thumbHeight]="config.thumbHeight"
             [thumbPosition]="config.thumbPosition"
             [imageSize]="'contain'"
             [counter]="false"
             [itemAutosize]="false"
             [autoHeight]="false"
             [playerInterval]="5000"
             [autoPlay]="false"></gallery>
  </section>
  <section>
    <h4>Funktionen:</h4>
    <ol>
      <li>Sicherer Login:</li>
      <ul>
        <li>Die App ist durch einen Login geschützt. Benutzer müssen sich entweder einloggen oder registrieren, um auf
          die Funktionen zuzugreifen.
        </li>
        <li>Nach erfolgreichem Login erhält der Benutzer ein Json Web Token, das 1 Tag lang gültig ist.</li>
      </ul>
      <li>Fangübersicht:</li>
      <ul>
        <li>Die Startseite zeigt dem Benutzer seine eingetragenen Fänge, standardmäßig nach Fangdatum sortiert.</li>
        <li>Der Benutzer kann die Anzahl der Fänge pro Seite anpassen und nach Fangdatum sortieren.</li>
        <li>Durch Klick auf "Detail zum Fang" gelangt der Benutzer zur Detailansicht des Fangs mit allen gespeicherten
          Daten.
        </li>
      </ul>
      <li>Öffentliche Fänge:</li>
      <ul>
        <li>Der Tab "Öffentliche Fänge" zeigt alle Fänge an, die von Benutzern als öffentlich markiert wurden.</li>
        <li>Durch Klick auf "Fang Detail" gelangt der Benutzer zur Detailansicht des Fanges.</li>
      </ul>
      <li>Detailansicht des Fangs:</li>
      <ul>
        <li>Die Detailansicht zeigt alle gespeicherten Daten zu einem Fang.</li>
        <li></li>
      </ul>
      <li>Fang bearbeiten:</li>
      <ul>
        <li>Durch Klick auf "Fang bearbeiten" können alle Daten zu einem Fang bearbeitet werden.</li>
        <li>Beim Bearbeiten werden keine aktuellen Wetterdaten geladen; alle Daten können manuell angepasst werden.</li>
      </ul>
      <li>Wetterinformationen:</li>
      <ul>
        <li>Im Menü "Wetter" werden dem Benutzer die aktuellen Wetterdaten für seinen Standort angezeigt.</li>
        <li>Wetterdaten fließen auch in einen neuen Fang ein.</li>
        <li>Der Benutzer kann auch die Mondphasen der nächsten 7 Tage überprüfen.</li>
      </ul>
      <li>7-Tage-Wetterprognose:</li>
      <ul>
        <li>Die Funktion "7 Tage Prognose" ermöglicht dem Benutzer, die Wetterdaten für die nächsten Tage abzurufen.
        </li>
        <li>Der Standort wird durch das Gerät ermittelt, und die Daten werden für die nächsten 7 Tage aufbereitet und
          angezeigt.
        </li>
      </ul>
    </ol>
  </section>
  <section>
    <h4>Verwendete Technologien:</h4>
    <ul>
      <li><b>Frontend:</b> Angular, Bootstrap</li>
      <li><b>Backend:</b> .NET Core 5 (RESTful API in C#)</li>
      <li><b>Datenbank:</b> Microsoft SQL</li>
    </ul>
    <h4>Umsetzung:</h4>
    <p>
      Das Frontend wurde mit Angular und Bootstrap für die Darstellung entwickelt. Das Backend wurde als RESTful API mit
      .NET Core 5 in C# programmiert. Die gesamte REST-API wurde vollständig asynchron implementiert, um eine effiziente
      Kommunikation zu gewährleisten. Die Daten werden sicher in einer Microsoft SQL-Datenbank gespeichert.
    </p>
    <p>
      Die Felchen Webapp steht zur Verfügung und kann gerne benutzt und getestet werden.
    </p>
  </section>
  <div class="demoButton">
    <button class="btn btn-success" (click)="onProject()">Zur Felchen Webapp</button>
  </div>
</div>


