<div class="container">
  <h3 class="text-center">Webseite GamingFactory - Info Portal für Spielekonsolenliebhaber:</h3>
  <p>Als leidenschaftlicher Sammler von Spielekonsolen habe ich im Laufe der Zeit gelernt, Defekte zu beheben, Konsolen
    zu reinigen und sogar Modifikationen vorzunehmen. Da sich mein Können herumsprach, erhielt ich Anfragen von anderen
    Konsolenenthusiasten. Dies führte zur Gründung der GamingFactory, in der ich in meiner Freizeit Reparaturen und
    Umbauten für Kunden durchführte.</p>
  <p>Mit der Entscheidung, eine neue Lehre als Applikationsentwickler zu absolvieren, wandelte sich der Fokus meiner
    Aktivitäten. Der Shop, der anfangs sogar einen Online-Shop beinhaltete, wurde mehr oder weniger eingestellt. Mit dem
    Ziel, mein neues Wissen in der Anwendungsentwicklung mit Angular zu vertiefen, entschied ich mich dazu, die Webseite
    GamingFactory zu programmieren.</p>
  <h4>Webseitenbeschreibung:</h4>
  <p>Die Webseite GamingFactory begann als umfassender Online-Shop für Konsolenreparaturen und -modifikationen. Aufgrund
    meiner Entscheidung, mich vollständig auf meine neue Karriere als Applikationsentwickler zu konzentrieren, wurden
    alle Reparaturdienstleistungen eingestellt. Die Webseite fungiert nun als kleines Informationsportal, das eine
    Vielzahl von Tutorials für Spielekonsolenliebhaber bietet.</p>
  <h4>Verwendete Technologien:</h4>
  <ul>
    <li><b>Frontend:</b> Angular, HTML 5, CSS 3, Angular Material</li>
    <li><b>Backend:</b> ASP.NET, C# (kleine API für das Kontaktformular)</li>
  </ul>
  <h4>Umsetzung:</h4>
  <p>Die Webseite wurde mit Angular entwickelt und nutzt verschiedene Angular Material Komponenten für ein ansprechendes
    Design. Das Kontaktformular kommuniziert über eine kleine API, die mit .NET und C# programmiert wurde. GamingFactory
    bietet somit eine Plattform, auf der Spielekonsolenenthusiasten Informationen und Tutorials finden können, um ihre
    eigenen Fähigkeiten in der Welt der Konsolenreparatur und -modifikation zu erweitern.</p>
</div>
