<nav ngxNavbarDynamicExpand class="navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/home">
      Tomasi Developing
    </a>
    <button class="navbar-toggler" type="button" (click)="collapse.toggle()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ngx-navbar-collapse #collapse="ngxNavbarCollapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active" (click)="collapse.close()">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/about" routerLinkActive="active" (click)="collapse.close()">Über mich</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/projects" routerLinkActive="active" (click)="collapse.close()">Projekte</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact" routerLinkActive="active" (click)="collapse.close()">Kontakt</a>
        </li>
      </ul>
    </ngx-navbar-collapse>
  </div>
</nav>
<div class="container">
  <router-outlet></router-outlet>
</div>
