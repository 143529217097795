import {Component} from '@angular/core';

@Component({
  selector: 'app-tomasi-developing',
  templateUrl: './tomasi-developing.component.html',
  styleUrls: ['./tomasi-developing.component.css']
})
export class TomasiDevelopingComponent {

}
