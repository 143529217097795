<div class="container">
  <section>
    <div class="row">
      <h3>Kontaktadresse</h3>
    </div>
    <div class="row">
      Tomasi Developing
    </div>
    <div class="row">
      Patrick Tomasi
    </div>
    <div class="row">
      Postgasse 10a
    </div>
    <div class="row">
      5079 Zeihen
    </div>
    <div class="row mt-2">
      <a href="mailto:info@tomasi-developing.ch"> info&#64;tomasi-developing.ch</a>
    </div>
  </section>
  <section class="mt-4">
    <div class="row">
      <h3>Haftungsausschluss</h3>
    </div>
    <div class="row">
      Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
      Zuverlässigkeit
      und Vollständigkeit der Informationen. Haftungsansprüche gegen den Autor wegen Schäden materieller oder
      immaterieller Art,
      welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch
      der Verbindung
      oder durch technische Störungen entstanden sind, werden ausgeschlossen. Alle Angebote sind unverbindlich.
      Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung
      zu verändern,
      zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
    </div>
  </section>
  <section class="mt-4">
    <div class="row">
      <h3>Haftung für Links</h3>
    </div>
    <div class="row">
      Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs Es wird jegliche
      Verantwortung
      für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des
      Nutzers oder der Nutzerin.
    </div>
  </section>
  <section class="mt-4">
    <div class="row">
      <h3>Urheberrechte</h3>
    </div>
    <div class="row">
      Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website gehören
      ausschliesslich
      TomasiDeveloping oder den speziell genannten Rechtsinhabern. Für die Reproduktion jeglicher Elemente ist die
      schriftliche Zustimmung
      der Urheberrechtsträger im Voraus einzuholen.
    </div>
    <div class="mt-4 mb-4 text-center">
      <a href="https://servertown.ch?id=15804" title="Webhosting, Exchange und VPS - Ihr starker Cloudhosting-Partner">
        <img src="https://servertown.ch/images/webhosting/servertown_logo_wo.png" alt="">
      </a>
    </div>
  </section>
</div>

