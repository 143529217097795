<div class="header">
  <img alt="Logo" class="img-fluid" src="./assets/images/logo.png" width="30%">
  <div class="banner">
    Tomasi - <span style="color: red">Developing</span>
  </div>
</div>
<div class="content">
  <h3>Willkommen bei Tomasi Developing</h3>
  <br>
  <section class="text-break">
    Mein Name ist Patrick Tomasi und ich bin seit einigen Jahren leidenschaftlicher Softwareentwickler.
    <br>
    Ursprünglich begann meine berufliche Laufbahn als Koch, doch meine Neugierde und Faszination für die Welt der
    Technologie führten
    mich im Jahr 2018 dazu, eine Ausbildung zum Applikationsentwickler mit EFZ zu absolvieren.
    <br>
    Seitdem bin ich in diesem spannenden Bereich tätig und habe mich kontinuierlich weiterentwickelt.
  </section>
  <section>
    Meine Expertise liegt vor allem in den Programmiersprachen C# (Net Framework und .NET Core), Angular und MSSQL.
    <br>
    Ich verfüge über umfangreiche Kenntnisse in diesen Technologien und habe in ihnen zahlreiche erfolgreiche Projekte
    umgesetzt.
    <br>
    Mit meinen fundierten Fähigkeiten in C# kann ich leistungsstarke Anwendungen entwickeln, die den
    Bedürfnissen meiner Kunden gerecht werden.
  </section>
  <section>
    Darüber hinaus bringe ich auch Erfahrung in anderen Bereichen mit.
    <br>
    Ich bin vertraut mit HTML 5 und CSS 3, was es mir
    ermöglicht, ansprechende und benutzerfreundliche Webseiten zu gestalten. Zudem habe ich umfangreiche Kenntnisse in
    Azure, einer Cloud-Plattform, die Unternehmen dabei unterstützt, ihre Anwendungen sicher und skalierbar
    bereitzustellen.
    <br>
    Des Weiteren beherrsche ich TypeScript und JavaScript, was es mir ermöglicht, dynamische und
    interaktive Funktionen in Webanwendungen zu implementieren.
  </section>
  <section>
    Ich habe meine Fähigkeiten und mein Wissen durch verschiedene Zertifizierungen untermauert. Ich besitze Zertifikate
    in Angular, Azure und TTN (The Things Network), die meine Kompetenz in diesen Bereichen belegen.
  </section>
  <section>
    Ich bin ein engagierter und motivierter Entwickler, der immer bestrebt ist, qualitativ hochwertigen Code zu
    schreiben und innovative Lösungen zu finden.
    <br>
    Ich arbeite gerne im Team und schätze den Austausch mit anderen
    Experten, um gemeinsam großartige Ergebnisse zu erzielen.
  </section>
  <div>
    <h5 class="text-center text-warning">Einige Werkzeuge, die ich benutzt und gelernt habe</h5>
    <div class="tools-box">
      <i class="devicon-angularjs-plain colored"></i>
      <i class="devicon-csharp-plain colored"></i>
      <i class="devicon-css3-plain-wordmark colored"></i>
      <i class="devicon-azure-plain colored"></i>
      <i class="devicon-arduino-plain-wordmark colored"></i>
      <i class="devicon-bootstrap-plain-wordmark colored"></i>
      <i class="devicon-confluence-original-wordmark colored"></i>
      <i class="devicon-docker-plain-wordmark colored"></i>
      <i class="devicon-dotnetcore-plain colored"></i>
      <i class="devicon-git-plain-wordmark colored"></i>
      <i class="devicon-github-original-wordmark"></i>
      <i class="devicon-grafana-original-wordmark colored"></i>
      <i class="devicon-html5-plain-wordmark colored"></i>
      <i class="devicon-javascript-plain colored"></i>
      <i class="devicon-jetbrains-plain colored"></i>
      <i class="devicon-jira-plain-wordmark colored"></i>
      <i class="devicon-kubernetes-plain-wordmark colored"></i>
      <i class="devicon-mongodb-plain-wordmark colored"></i>
      <i class="devicon-nodejs-plain-wordmark colored"></i>
      <i class="devicon-dot-net-plain-wordmark colored"></i>
      <i class="devicon-ionic-original-wordmark colored"></i>
      <i class="devicon-nestjs-plain-wordmark colored"></i>
      <i class="devicon-sass-original colored"></i>
      <i class="devicon-tailwindcss-original-wordmark colored"></i>
      <i class="devicon-raspberrypi-line-wordmark colored"></i>
      <i class="devicon-redis-plain-wordmark colored"></i>
      <i class="devicon-microsoftsqlserver-plain-wordmark"></i>
      <i class="devicon-visualstudio-plain-wordmark colored"></i>
      <i class="devicon-vscode-plain-wordmark colored"></i>
      <i class="devicon-webstorm-plain-wordmark colored"></i>
    </div>
  </div>
  <div class="certification">
    <img alt="Azure Developer" class="img-fluid" src="../../assets/images/azureDeveloper.png">
    <img alt="Angular Level 1" class="img-fluid" src="../../assets/images/angularLevel1.png">
    <img alt="Angular Level 2" class="img-fluid" src="../../assets/images/angularLevel2.png">
  </div>
</div>



