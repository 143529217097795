<div class="container">
  <h3>Projekte:</h3>
  <p>Die aufgelisteten Projekte sind alles private Projekte die ich in meiner Freizeit als lern oder Weiterbildung entwickelt habe</p>
  <div class="row mb-5">
    <div class="col-md-12">
      <div class="main-timeline3">
        <div class="timeline">
          <div class="timeline-content">
            <span class="year">12.2023</span>
            <h3 class="title">Dättwiler Weiher</h3>
            <p class="description">
              Dättwiler Weiher Online Platform, die durch fortschrittliche LoRa-Technologie und intuitive Benutzeroberfläche eine Echtzeit-Wassertemperaturüberwachung sowie Lizenzverwaltung für Fischer bietet, ergänzt durch einen Admin-Bereich mit Mitgliederverwaltung und Swiss QR Rechnung.
            </p>
            <button routerLink="./daettwilerpond" class="btn btn-success btn-sm">Zum Projekt</button>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-content">
            <span class="year">06.2022</span>
            <h3 class="title">Swiss Waters API</h3>
            <p class="description">
              Auf der Webseite werden diverse Daten, wie Temperatur, Abfluss, Pegel, Sauerstoff und Trübung von Schweizer Gewässer dargestellt.
              Dank Zugriff auf die Daten des Bundesamt für Umwelt sind 243 Sationen verfügbar
            </p>
            <button routerLink="./swisswaters" class="btn btn-success btn-sm">Zum Projekt</button>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-content">
            <span class="year">01.2022</span>
            <h3 class="title">Kostenmanager</h3>
            <p class="description">
              Ist eine Webapplikation mit der sich Ausgaben wie auch Einnahmen erfassen und Verwalten lassen.
              Der Benutzer bekommt ein Überblick über die Ausgaben und kann sich jeder Zeit Statistiken als Excel herunterladen.
            </p>
            <button routerLink="./expensetracker" class="btn btn-success btn-sm">Zum Projekt</button>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-content">
            <span class="year">12.2021</span>
            <h3 class="title">Meerschweinchen Gesundheits - Check</h3>
            <p class="description">
              Ist eine kleine Webapplikation um Gesundheits - Check für Meerschweinchen zu speichern.
              Die Applikation wurde mit Angular 13.0.3 und .NET Core 6 umgesetzt.
            </p>
            <button routerLink="./guineaPig" class="btn btn-success btn-sm">Zum Projekt</button>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-content">
            <span class="year">06.2021</span>
            <h3 class="title">Felchen Webapp</h3>
            <p class="description">
              Die Felchen Webapp ist eine Webbasierte neue Version der Android Felchen App.
              Die Applikation wurde mit Angular 12.0.3 umgesetzt.
            </p>
            <button routerLink="./felchenWebApp" class="btn btn-success btn-sm">Zum Projekt</button>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-content">
            <span class="year">04.2021</span>
            <h3 class="title">Webseite Tomasi Developing</h3>
            <p class="description">
              Die Webseite wurde mit Angular 11.2.7 umgesetzt. Aktuell wird eine RESTApi in .NET für das Kontaktformular verwendet.
            </p>
            <button routerLink="./tomasiDeveloping" class="btn btn-success btn-sm">Zum Projekt</button>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-content">
            <span class="year">02.2021</span>
            <h3 class="title">Fischerlizenz Manager</h3>
            <p class="description">
              Der Fischerlizenz Manager ist eine Web Applikation welche mit Angular 11.1.2 umgesetzt wurde.
              Als backend wurde eine RESTApi mit .NET Core 5 programmiert.
            </p>
            <button routerLink="./fishingLizenzManager" class="btn btn-success btn-sm">Zum Projekt</button>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-content">
            <span class="year">12.2020</span>
            <h3 class="title">Game Inventory</h3>
            <p class="description">
              Game Inventory ist eine Web Applikation und wurde mit Angular 11.0.4 umgesetzt. Für das backend wurde eine RESTApi in .NET verwendet.
              Die Daten werden in einer MSSQL Datenbank gespeichert.
            </p>
            <button routerLink="./gameInventory" class="btn btn-success btn-sm">Zum Projekt</button>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-content">
            <span class="year">09.2020</span>
            <h3 class="title">Felchen App</h3>
            <p class="description">
              Die Felchen App wurde mit Ionic umgesetzt. Als backend wurde eine RESTApi in .NET programmiert,
              die Daten werden in einer MSSQL Datenbank gespeichert.
            </p>
            <button routerLink="./felchenApp" class="btn btn-success btn-sm">Zum Projekt</button>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-content">
            <span class="year">09.2020</span>
            <h3 class="title">Webseite Glasbläserei Egli</h3>
            <p class="description">
              Die Webseite wurde mit Angular 9.1.7 umgesetzt. Als backend wurde eine RESTApi mit .NET programmiert welche mit einer MSSQL Datenbank kommuniziert.
            </p>
            <button routerLink="./glasblaeserei-egli" class="btn btn-success btn-sm">Zum Projekt</button>
          </div>
        </div>
        <div class="timeline">
          <div class="timeline-content">
            <span class="year">06.2020</span>
            <h3 class="title">Webseite GamingFactory</h3>
            <p class="description">
              Die Webseite wurde mit Angular 9.1.6 umgesetzt. Als backend wurde eine RESTApi mit .NET programmiert.
            </p>
            <button routerLink="./gamingFactory" class="btn btn-success btn-sm">Zum Projekt</button>
          </div>
        </div>
      </div>
      <div class="diverse">Diverse kleinere Projekte und Testprojekte findet Ihr in meinem GitHub Account
        <br><i class="fa fa-github fa-2x" (click)="onClick()"></i>
      </div>
      <div class="text-center mt-2">
        <h3>Status laufende Projekte:</h3>
        <img (click)="onUptimeRobot()" src="./assets/images/uptimerobot-logo.png" alt="Uptime Robot">
      </div>
    </div>
  </div>
</div>


