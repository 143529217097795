<div class="container">
  <div class="content">
    <h1>Oops!</h1>
    <h3>Wir können die Seite, die Sie suchen <code>"{{searchUrl}}"</code>, nicht finden.</h3>
    <br>
    <code>Error 404</code>
    <br>
    <br>
    <p>Hier sind stattdessen einige hilfreiche Links:</p>
    <ul>
      <li routerLink="/home">Home</li>
      <li routerLink="/about">Über mich</li>
      <li routerLink="/projects">Projekte</li>
      <li routerLink="/contact">Kontakt</li>
    </ul>
  </div>
  <div class="image">
    <img src="./assets/images/notFound.jpg" class="img-thumbnail" alt="Logo">
  </div>
</div>
