<div class="footer bg-light">
  <div class="content">
    <div routerLink="/impressum">Impressum</div>
    <div routerLink="/contact">Kontakt</div>
    <div routerLink="/privacy">Datenschutz</div>
  </div>
  <div class="copyright">
    <div>Copyright &copy; 2020 -  {{currentYear}} All Rights Reserved by Tomasi Developing</div>
  </div>
</div>

